<template>
    <div class="grid box-time-line-container m-4">
        <div class="col-10">
            <div class="grid">
                <div class="col-12 mt-8 box-items border-top-1 surface-border">
                    <div class="grid mt-8">
                        <div class="col-12 justify-content-center pt-0" v-for="(item, index) in items" :key="index">
                            <div class="grid">
                                <div class="col-6 pt-0 border-right-1 surface-border text-lg box-item">
                                    <p class="text-xl text-right pb-8 mr-4 pr-4">{{ item.data }}</p>
                                    <div :class="`box-icon ${status.CRIADO}`" v-if="item.status === status.CRIADO">
                                        <i class="pi pi-circle text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.PENDENTE}`" v-if="item.status === status.PENDENTE">
                                        <i class="pi pi-clock text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.ATUALIZACAO}`" v-if="item.status === status.ATUALIZACAO">
                                        <i class="pi pi-sync text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon pl-1 ${status.INICIADO}`" v-if="item.status === status.INICIADO">
                                        <i class="pi pi-play text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.EM_ANDAMENTO}`" v-if="item.status === status.EM_ANDAMENTO">
                                        <i class="pi pi-spin pi-spinner text-2xl"></i>
                                    </div>
                                    <div
                                        :class="`box-icon ${status.ATUALIZACAO_ACOMPANHAMENTO}`"
                                        v-if="item.status === status.ATUALIZACAO_ACOMPANHAMENTO"
                                    >
                                        <i class="pi pi-file-o text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.COMENTARIO_INTERNO}`" v-if="item.status === status.COMENTARIO_INTERNO">
                                        <i class="pi pi-comment text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.COMENTARIO}`" v-if="item.status === status.COMENTARIO">
                                        <i class="pi pi-comments text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.ANEXOS}`" v-if="item.status === status.ANEXOS">
                                        <i class="pi pi-paperclip text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.CONCLUIDO}`" v-if="item.status === status.CONCLUIDO">
                                        <i class="pi pi-check text-2xl"></i>
                                    </div>
                                    <div :class="`box-icon ${status.EXCLUIDO}`" v-if="item.status === status.EXCLUIDO">
                                        <i class="pi pi-times text-2xl"></i>
                                    </div>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.CRIADO">
                                    <p>Plano de ação criado</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.PENDENTE">
                                    <p>Status pendente</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.ATUALIZACAO">
                                    <p>Atualização</p>
                                    <p v-if="item.origem" v-html="item.origem" />
                                    <p class="pb-4" v-if="item.descricao" v-html="item.descricao" />
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.INICIADO">
                                    <p>Iniciado</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.EM_ANDAMENTO">
                                    <p>Status em andamento</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.ATUALIZACAO_ACOMPANHAMENTO">
                                    <p>Atualização de acompanhamento</p>
                                    <p v-html="item.descricao" />
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.COMENTARIO_INTERNO">
                                    <p>Comentário interno</p>
                                    <p v-if="item.descricao" v-html="item.descricao" />
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.COMENTARIO">
                                    <p>Comentário</p>
                                    <p v-if="item.descricao" v-html="item.descricao" />
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.ANEXOS">
                                    <p>Anexos</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.CONCLUIDO">
                                    <p>Concluído</p>
                                </div>
                                <div :class="`col-6 pt-0 pl-6 text-xl`" v-if="item.status === status.EXCLUIDO">
                                    <p>Excluído</p>
                                    <p v-if="item.descricao">Justificativa: {{ item.descricao }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
export default {
    name: 'i-time-line',
    props: {
        selectedId: {
            type: Object
        }
    },
    data() {
        return {
            status: {
                CRIADO: 'CRIADO',
                PENDENTE: 'PENDENTE',
                ATUALIZACAO: 'ATUALIZACAO',
                INICIADO: 'INICIADO',
                EM_ANDAMENTO: 'EM_ANDAMENTO',
                ATUALIZACAO_ACOMPANHAMENTO: 'ATUALIZACAO_ACOMPANHAMENTO',
                COMENTARIO_INTERNO: 'COMENTARIO_INTERNO',
                COMENTARIO: 'COMENTARIO',
                ANEXOS: 'ANEXOS',
                CONCLUIDO: 'CONCLUIDO',
                EXCLUIDO: 'EXCLUIDO'
            },
            items: []
        };
    },

    async mounted() {
        if (this.selectedId) {
            const { data = [] } = await new BaseService(`/linha-do-tempo/${this.selectedId}`).findAll({});
            this.items = data;
        }
    },

    methods: {},

    watch: {}
};
</script>

<style scoped>
.box-items {
    height: 50vh;
    overflow-y: scroll;
}
.box-item {
    position: relative;
}
.box-icon {
    position: absolute;
    top: 0;
    right: -16px;
    text-align: center;
    margin: 0;
    width: 32px;
    height: 32px;
    background-color: #dee2e6;
    color: #ffffff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-time-line-container {
    display: flex;
    justify-content: center;
}

.PENDENTE {
    background-color: #ffa500;
}
.ATUALIZACAO {
    background-color: #4784ff;
}
.INICIADO {
    background-color: #ffa500;
}
.EM_ANDAMENTO {
    background-color: #ffa500;
}
.ATUALIZACAO_ACOMPANHAMENTO {
    background-color: #ffa500;
}
.COMENTARIO_INTERNO {
    background-color: #ffa500;
}
.COMENTARIO {
    background-color: #ffa500;
}
.ANEXOS {
    background-color: #ffa500;
}
.CONCLUIDO {
    background-color: #00c500;
}
.EXCLUIDO {
    background-color: #700d06;
}
</style>
